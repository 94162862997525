'use client'

import type { HTMLAttributes } from 'react'
import { useEffect, useState } from 'react'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import type { Options } from '@contentful/rich-text-react-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import type { Document } from '@contentful/rich-text-types'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'

const renderOption: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return (
        <p
          className={cn(
            'mb-4 max-w-[670px] justify-center text-center text-xs text-white last:flex last:gap-4 md:justify-start md:text-left lg:justify-start lg:text-left'
          )}
        >
          {children}
        </p>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const href = node?.data?.uri
      const isCookieSettingLink = children?.toString().includes('Do not sell')
      return (
        <Link
          href={href}
          id={isCookieSettingLink ? 'ot-sdk-link' : ''}
          iconSize="sm"
          className={cn(
            buttonVariants({ variant: 'secondary-link' }),
            'p-0 text-xs font-normal text-white underline hover:text-white hover:underline active:text-white active:underline',
            isCookieSettingLink && 'ot-sdk-show-settings'
          )}
          {...(isCookieSettingLink && {
            target: '_self',
            rel: 'noopener noreferrer',
          })}
        >
          {children}
        </Link>
      )
    },
  },
}

export function SiteFooter({ className }: HTMLAttributes<HTMLElement>) {
  const path = usePathname() ?? '/'
  const [footerContent, setFooterContent] = useState<Document | null>()

  useEffect(() => {
    const fetchFooterContent = async () => {
      const params = new URLSearchParams({ path: path })
      try {
        const response = await fetch(`/api/footer?${params}`)
        const { data } = await response.json()
        setFooterContent(data)
      } catch (error) {
        throw error
      }
    }

    fetchFooterContent()
  }, [path])

  return (
    <footer className={cn('w-screen bg-hudson-navy-950', className)}>
      <div className="mx-auto max-w-screen-2xl">
        <div className="flex flex-col gap-8 px-5 py-7 md:gap-6 md:px-6 md:pb-6 md:pt-7 lg:gap-6 lg:px-6 lg:pb-6 lg:pt-7">
          <Image
            className="self-center sm:self-start"
            src={'/assets/svg/guardian-well-footer-logo.svg'}
            alt={'Gw Logo'}
            width={214}
            height={27}
          />

          <hr className="h-px border-0 bg-hudson-navy-700" />

          {footerContent && (
            <div className="space-y-4">
              {documentToReactComponents(footerContent, renderOption)}
            </div>
          )}
        </div>
      </div>
    </footer>
  )
}
