import { TAGS } from '@/contentful/constants'
import { clsx, type ClassValue } from 'clsx'
import type { Metadata } from 'contentful/dist/types/types/metadata'
import { sha256 } from 'js-sha256'
import type { JwtPayload } from 'jsonwebtoken'
import jwt from 'jsonwebtoken'
import type { User } from 'next-auth'
import { twMerge } from 'tailwind-merge'

import type { Wellness } from '@/lib/types/entitlements'
import { Vendor } from '@/lib/types/entitlements'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function exists<T>(value: T): value is NonNullable<T> {
  return Boolean(value)
}

export function identity<T>(item: T): T {
  return item
}

export function checkBenefitsEligibility(wellness: Wellness | null): boolean {
  return !!wellness?.benefits?.length
}

export function filterByPartner(hasBenefits: boolean, entitlements: Wellness) {
  if (!hasBenefits) return undefined
  const hasUprise = entitlements?.benefits.some(
    (item) => item.vendor === Vendor.UpriseHealth
  )
  const hasCompsych = entitlements?.benefits.some(
    (item) => item.vendor === Vendor.ComPsych
  )
  return hasUprise && !hasCompsych ? Vendor.ComPsych : undefined
}

export function groupBy<T, K extends string | number | symbol>(
  array: T[],
  getKey: (item: T) => K | K[]
): Record<K, T[]> {
  return array.reduce(
    (result, item) => {
      const keys = [getKey(item)].flat() as K[]

      keys.forEach((groupKey: K) => {
        if (!result[groupKey]) {
          result[groupKey] = []
        }

        result[groupKey].push(item)
      })

      return result
    },
    {} as Record<K, T[]>
  )
}

export function uniqBy<T, K>(array: T[], getKey: (item: T) => K) {
  const seen = new Map<K, boolean>()

  return array.filter((item) => {
    const key = getKey(item)

    if (seen.has(key)) return false

    seen.set(key, true)

    return true
  })
}

export function sortBy<T, K extends number | string>(
  array: T[],
  getKey: (item: T) => K,
  desc: boolean = false
) {
  return [...array].sort(function (a, b) {
    const keyA = getKey(a)
    const keyB = getKey(b)

    if (keyA < keyB) return desc ? 1 : -1
    if (keyA > keyB) return desc ? -1 : 1

    return 0
  })
}

export function sortDescBy<T, K extends number | string>(
  array: T[],
  getKey: (item: T) => K
) {
  return sortBy(array, getKey, true)
}

export function stubTrue(): boolean {
  return true
}

export function hashUserLoginId(user: User) {
  return user! && sha256(`${user.name}-${user.email}`)
}

export interface CustomJwtPayload extends JwtPayload {
  Groups: string[]
}

export function decodeToken(user: User) {
  if (!user) throw new Error('User object was undefined in decodeToken')
  return jwt.decode(user.idToken) as CustomJwtPayload
}

export const hasTag = (tag: string) => (m: Metadata) =>
  m.tags.some(({ sys }) => sys.id === tag)
export const hasMemcoTag = hasTag(TAGS.MEMCO_TAG_ID)
