export const CONTENT_ENTRIES = {
  FOOTER_AUTHENTICATED: 'footer-authenticated',
  FOOTER_UNAUTHENTICATED: 'footer-unauthenticated',
  JOIN_MEMCO_MODAL: 'join-memco-modal',
  JOIN_MEMCO_MODAL_PELOTON_CARD: 'join-memco-modal-peloton-card',
  MEMCO_USER_MODAL: 'manage-leave-memco-modal',
  JOIN_MEMCO_TEXT: 'join-memco-text',
  MANAGE_MEMCO_TEXT: 'manage-memco-text',
}

export const TAGS = {
  MEMCO_TAG_ID: 'appi1108MemcoMembership',
}
