import type { UrlObject } from 'url'
import React from 'react'
import LinkPrimitive from 'next/link'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { ExternalLink } from 'lucide-react'

import { cn } from '@/lib/utils'

const linkVariants = cva(
  'focus-visible:underline focus-visible:shadow-ring focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accessibility-ring active:underline disabled:pointer-events-none',
  {
    variants: {
      variant: {
        default: '',
        'subcategory-badge': 'rounded-lg',
      },
      size: {
        sm: 'gap-1.5',
        md: 'gap-2',
        lg: 'gap-3',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  }
)

const iconSizeVariants = cva('', {
  variants: {
    size: {
      sm: 'size-3',
      md: 'size-3.5',
      lg: 'size-4',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const isExternalUrl = (href: string | UrlObject, base?: string): boolean => {
  try {
    const hrefString = typeof href === 'string' ? href : (href.href ?? '')
    const fullUrl = new URL(hrefString, base)
    return !fullUrl.href.startsWith(base!)
  } catch {
    return false
  }
}

interface LinkProps
  extends React.ComponentPropsWithoutRef<typeof LinkPrimitive>,
    VariantProps<typeof linkVariants> {
  iconSize?: VariantProps<typeof iconSizeVariants>['size']
}

const Link = React.forwardRef<
  React.ElementRef<typeof LinkPrimitive>,
  LinkProps
>(({ className, href, iconSize, children, ...props }, ref) => {
  if (!href) return null
  const base = process.env.NEXT_PUBLIC_BASE_URL!
  const isExternal = isExternalUrl(href, base)

  return (
    <LinkPrimitive
      className={cn(
        isExternal && 'inline-flex items-center gap-2',
        linkVariants(),
        className
      )}
      href={href}
      ref={ref}
      {...(isExternal && {
        target: '_blank',
        rel: 'noopener noreferrer',
      })}
      {...props}
    >
      {children}
      {isExternal && (
        <ExternalLink
          className={cn('shrink-0', iconSizeVariants({ size: iconSize }))}
          aria-label="(Opens in a new tab)"
        />
      )}
    </LinkPrimitive>
  )
})
Link.displayName = 'Link'

export { Link, linkVariants, type LinkProps }
